<template>
  <button :class="buttonClass" class="rounded gap-5 text-white font-roboto-normal" @click="clickHandler">
    <Loading v-if="isLoading" :extraClassName="variant === 'white-blue' ? 'light-blue' : ''" />
    <span v-else>{{ text }}</span>
  </button>
</template>

<script lang="ts" setup>
interface ComponentProps {
  text: string
  onClick?: () => void
  disabled?: boolean
  variant?: 'normal' | 'white' | 'white-blue' | 'white-grey' | 'light' | 'gradient' | 'danger'
  isLoading?: boolean
  type?: 'normal' | 'small'
  isLong?: boolean
}

const props = withDefaults(defineProps<ComponentProps>(), {
  text: 'Click me...',
  disabled: false,
  variant: 'normal',
  isLoading: false,
  type: 'normal',
  isLong: false
})

const buttonClass = ref<string>('')

const clickHandler = () => {
  if (!props.disabled && !props.isLoading) {
    props.onClick?.()
  }
}

const generateButtonClass = () => {
  let classNames = ''

  // Background
  if (props.variant === 'white') {
    classNames += ' bg-white !text-cs-light-blue'
  } else if (props.variant === 'white-blue') {
    classNames += ' bg-white dark:bg-transparent !text-cs-light-blue border border-cs-light-blue rounded-[5px] py-[2px] px-2'
  } else if (props.variant === 'white-grey') {
    classNames +=
      ' bg-white dark:bg-transparent !text-cs-medium-grey border border-cs-light-grey dark:border-cs-medium-grey rounded-[5px] py-[2px] px-2'
  } else if (props.variant === 'light') {
    classNames += ' bg-cs-light-blue !text-white'
  } else if (props.variant === 'gradient') {
    classNames += ' bg-gradient-to-r from-[#76EFB5] to-[#1D78C7] !text-white'
  } else if (props.variant === 'danger') {
    classNames += ' bg-[#EE6161] dark:bg-[#EE6161] !text-white'
  }
  if (props.disabled) {
    classNames += ' bg-gray-400'
  }

  // Size
  if (props.type === 'small') {
    classNames += ' text-sm px-2 py-[2px] leading-6'
  } else {
    classNames += ' text-base py-2 lg:py-4 px-4 lg:px-12 leading-5'
  }

  if (props.isLong) {
    classNames += ' min-w-[205px]'
  }

  buttonClass.value = classNames
}

generateButtonClass()

watch(() => props.disabled, generateButtonClass, {immediate: true})
watch(() => props.variant, generateButtonClass, {immediate: true})
</script>
